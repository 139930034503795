<template>
    <r-e-dialog :title="dialogTitle" :visible.sync="dialogVisible" show-footer @click-submit="clickSubmit" width="700px"
                @click-cancel="clickCancel">
        <el-form title="" ref="formPublish" label-width="80px" size="small" :model="formPublish" :rules="rules">
            <el-form-item label="版本标题" prop="name">
                <el-input v-model="formPublish.name"/>
            </el-form-item>
            <el-form-item label="版本内容" prop="common">
                <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 5}" v-model="formPublish.common"/>
            </el-form-item>
        </el-form>
    </r-e-dialog>
</template>

<script>
    import { addVersion, updateVersion } from "@/api/version-list";
    import { MessageSuccess } from "@custom/message";
    import { timeFormat } from "@custom/index";
    export default {
        name: "layer-add-update-version",
        data() {
            return {
                dialogVisible: false,
                formPublish: {
                    uuid: null,
                    name: null,
                    common: null,
                },
                rules: {
                    name: [{required: true, message: '请输入版本标题', trigger: 'blur'}],
                    common: [{required: true, message: '请输入版本内容', trigger: 'blur'}],
                },
                dialogTitle: '',
                type: 'add',
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            };
        },
        components: {},
        async mounted() {
        },
        methods: {
            openDialog(type, data) {
                this.type = type;
                this.dialogTitle = type === "add" ? '发布版本' : '修改版本';
                if (type === 'add') console.log(type);
                else {
                    let oldFormPublish = {...this.formPublish};
                    this.formPublish = {...oldFormPublish, ...data}
                }
                this.dialogVisible = true;
            },

            clickSubmit() {
                // 表单提交
                this.$refs["formPublish"].validate((valid) => {
                    if (valid) {
                        let data = this.formPublish;
                        if (this.type === 'add') this.addVersion({...data});
                        else this.updateVersion({...data});
                    }
                });
            },
            clickCancel() {
                this.$emit("handleSearch");
                this.formPublish = {uuid: null, name: null, common: null,}
                this.dialogVisible = false;
            },
            addVersion(data) {
                let that = this;
                let loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                addVersion({...data}).then(res => {
                    MessageSuccess('添加成功');
                    that.clickCancel();
                }).finally(() => loading.close());
            },
            updateVersion(data) {
                let that = this;
                let loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                updateVersion({...data}).then(res => {
                    MessageSuccess('修改成功');
                    that.clickCancel();
                }).finally(() => loading.close());
            },
        }
    }
</script>

<style scoped>

</style>